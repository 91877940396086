import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, Card, CardContent, CardMedia, Snackbar, Switch } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { AdifParser } from "adif-parser-ts";
import domtoimage from "dom-to-image";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import "./App.css";
import bgbw from "./bg-bw.jpg";
import bg from "./bg.jpg";
import logo from "./logo.svg";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDGjRqn_drXeTZvW9GyX7Lj5FqPYxcQcbc",
  authDomain: "lu1cbq.firebaseapp.com",
  projectId: "lu1cbq",
  storageBucket: "lu1cbq.appspot.com",
  messagingSenderId: "24068953823",
  appId: "1:24068953823:web:70a50dcfed4d3075fa3b6a",
  measurementId: "G-RKTMEXY1BQ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const adifPathReference = ref(storage, "TOTAL2.adi");

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

let initialized = false;

function App() {
  const [adif, setAdif] = useState(null);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [card, setCard] = useState(null);
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    initialized = true;

    const qrz = new URL(document.location).searchParams.get("qrz");
    if (qrz) {
      setInput(qrz);
    }

    fetchAdif();
  }, []);

  useEffect(() => {
    const qrz = new URL(document.location).searchParams.get("qrz");
    if (qrz && adif) {
      search();
    }
  }, [adif]);

  useEffect(() => {
    if (tableRows.length === 1) {
      selectCard(tableRows[0]);
    }
  }, [tableRows]);

  const fetchAdif = async () => {
    if (adif) {
      return;
    }
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      const adifLink = await getDownloadURL(adifPathReference);
      const response = await fetch(adifLink);
      const adifString = await response.text();
      const parsedAdif = AdifParser.parseAdi(adifString);
      setAdif(parsedAdif);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const search = () => {
    setLoading(true);
    setNotFound(false);
    setTableRows([]);
    setCard(null);
    const searchResult = adif.records.filter((record) => {
      return record.call === input;
    });
    if (searchResult.length === 0) {
      setNotFound(true);
      setLoading(false);
      return;
    }
    logEvent(analytics, "search", {
      qrz: input,
    });
    const tableRows = searchResult.map((record) => {
      return {
        call: record.call,
        address: record.address,
        qso_date: `${record.qso_date.substring(6, 8)}-${record.qso_date.substring(4, 6)}-${record.qso_date.substring(
          0,
          4
        )}`,
        band: record.band,
        mode: record.mode,
        time_on: `${record.time_on.substring(0, 2)}:${record.time_on.substring(2, 4)}`,
      };
    });
    setTableRows(tableRows);
    setLoading(false);
  };

  const selectCard = async (row) => {
    if (card !== row) {
      const section = document.querySelector("#card");
      section.scrollIntoView({ behavior: "smooth", block: "center" });
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    setCard(row !== card ? row : null);
  };

  const downloadCard = async () => {
    const node = document.getElementById("card");
    const dataUrl = await domtoimage.toJpeg(node);
    var link = document.createElement("a");
    link.download = "card.jpeg";
    link.href = dataUrl;
    link.click();
    setSnackbarVisible(true);
    logEvent(analytics, "download", {
      qrz: card?.call,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarVisible(false);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Snackbar open={snackbarVisible} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          ¡Descarga iniciada!
        </Alert>
      </Snackbar>
      <div className="App">
        <header className="App-header">
          <Box sx={{ mt: 6 }} />
          <img src={logo} className="App-logo" alt="logo" />
          <Box sx={{ mt: 2 }} />
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString("LU1CBQ").start();
            }}
          />
          <Box sx={{ mt: 3 }} />
          <span style={{ fontSize: 24 }}>Descargar mi tarjeta QSL</span>
          <Box sx={{ m: 2 }} />
          <TextField
            id="outlined-basic"
            label="Ingrese su licencia..."
            variant="outlined"
            inputProps={{ style: { textTransform: "uppercase" } }}
            value={input}
            disabled={loading && adif}
            onChange={(input) => setInput(input.target.value.toUpperCase().trim())}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !loading && input !== "") {
                search();
              }
            }}
          />
          <Box sx={{ m: 2 }} />
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={loading || input === ""}
            onClick={() => search()}
          >
            Buscar Licencia
          </LoadingButton>
          <Box sx={{ m: 2 }} />
          {notFound && (
            <span style={{ fontSize: 18, color: "#FF4444" }}>
              No se encontraron registros para la licencia ingresada.
            </span>
          )}
          {tableRows.length > 0 && (
            <Box sx={{ my: 2, mx: 5 }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" table-layout="fixed">
                  <TableHead>
                    <TableRow>
                      <TableCell>Seleccionar</TableCell>
                      <TableCell>QRZ</TableCell>
                      <TableCell>FECHA</TableCell>
                      <TableCell>UTC</TableCell>
                      <TableCell>BANDA</TableCell>
                      <TableCell>MODO</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map((row) => (
                      <TableRow key={Math.random()} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell align="center">
                          <Switch checked={row === card} onChange={() => selectCard(row)} />
                        </TableCell>
                        <TableCell>{row.call}</TableCell>
                        <TableCell>{row.qso_date}</TableCell>
                        <TableCell>{row.time_on}</TableCell>
                        <TableCell>{row.band}</TableCell>
                        <TableCell>{row.mode}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {tableRows.length > 0 && (
            <Box sx={{ m: 3 }}>
              <Paper elevation={10}>
                <Card id="card" sx={{ minWidth: 600 }}>
                  <Fade in={!card} timeout={{ appear: 800, exit: 400 }} style={{ position: "absolute" }}>
                    <CardMedia component="img" height="500" width="300" image={bgbw} alt="LU1CBQ" />
                  </Fade>
                  <Fade in={card} timeout={{ appear: 800, exit: 400 }}>
                    <CardMedia component="img" height="500" width="300" image={bg} alt="LU1CBQ" />
                  </Fade>
                  <CardContent>
                    <>
                      <table className="cardTable">
                        <thead>
                          <tr>
                            <th style={{ userSelect: "none" }}>QRZ</th>
                            <th style={{ userSelect: "none" }}>FECHA</th>
                            <th style={{ userSelect: "none" }}>UTC</th>
                            <th style={{ userSelect: "none" }}>BANDA</th>
                            <th style={{ userSelect: "none" }}>MODO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ userSelect: "none" }}>{card ? card.call : "-"}</td>
                            <td style={{ userSelect: "none" }}>{card ? card.qso_date : "-"}</td>
                            <td style={{ userSelect: "none" }}>{card ? card.time_on : "-"}</td>
                            <td style={{ userSelect: "none" }}>{card ? card.band : "-"}</td>
                            <td style={{ userSelect: "none" }}>{card ? card.mode : "-"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  </CardContent>
                </Card>
              </Paper>
              <Box sx={{ mt: 3 }} />
              <Button variant="contained" startIcon={<DownloadIcon />} disabled={!card} onClick={() => downloadCard()}>
                Descargar
              </Button>
            </Box>
          )}
          <Box sx={{ mt: 3 }} />
          <hr style={{ width: "500px" }}></hr>
          <Box sx={{ mt: 2 }} />
          <p style={{ fontSize: 30 }}>Logbook</p>
          <Box style={{ backgroundColor: "white" }}>
            <iframe
              title="Logbook"
              align="top"
              frameBorder="0"
              height="550"
              scrolling="yes"
              src="https://logbook.qrz.com/lbstat/LU1CBQ/"
              width="800"
            ></iframe>
          </Box>
          <Box sx={{ mt: 7 }} />
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
